import { IMPORT_MAP } from '@app/importMap'

import { makeLoginRedirect } from '@app/utils/getShouldLoginRedirect'
import { AsyncRoute } from '@app/utils/routing/types'

const redirectToAuth: AsyncRoute['component'] = async location => makeLoginRedirect(`${location.pathname || '/'}${location.search || ''}${location.hash || ''}`)
const Announcements = () => IMPORT_MAP.routes.Announcements.SitterAnnouncements().then(m => m.default)
const PublicAnnouncement = () => import('@app/routes/Announcements/PublicAnnouncement').then(m => m.PublicAnnouncement)

// @routes declaration
/** redirects to main page for guest */
const routes: (component?: AsyncRoute['component']) => AsyncRoute[] = (component = redirectToAuth) => [
  {
    path: '/m',
    event_id: 'unauth_conversations',
    component,
  },
  {
    path: '/m/:token',
    event_id: 'unauth_conversation',
    event_params: {
      token: 'token',
    },
    component,
  },
  {
    path: '/news',
    event_id: 'unauth_news',
    component,
  },
  {
    path: '/settings',
    event_id: 'unauth_settings',
    component,
  },
  {
    path: '/history',
    event_id: 'unauth_history',
    component,
  },
  {
    path: '/referrals',
    event_id: 'unauth_referrals',
    component,
  },
  {
    path: '/billing',
    event_id: 'unauth_billing',
    component,
  },
  {
    path: '/trusted',
    event_id: 'unauth_trusted',
    component,
  },
  {
    path: '/favorites',
    event_id: 'unauth_favorites',
    component,
  },
  {
    path: '/promocode',
    event_id: 'unauth_promocode',
    component: () => IMPORT_MAP.routes.Settings.PromocodeUnauth().then(m => m.Promocode),
  },
  {
    path: '/schedule',
    event_id: 'unauth_schedule',
    component,
  },
  {
    path: '/reviews',
    event_id: 'unauth_reviews',
    component,
  },
  {
    path: '/announcements',
    event_id: 'depersonalized_announcements',
    component: Announcements,
  },
  {
    path: '/announcements/new',
    event_id: 'unauth_announcements_new',
    component,
  },
  {
    path: '/announcements/:id',
    event_id: 'depersonalized_announcement',
    component: PublicAnnouncement,
  },
  {
    path: '/rules',
    event_id: 'unauth_rules',
    component,
  },
  {
    path: '/telegram/code',
    event_id: 'telegram_code',
    component,
  },
]

export default routes
